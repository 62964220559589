<template>
  <div>
    <div class="title-wrap">
      <h6>审核列表</h6>
      <!-- TODO:暂时没有导出功能 -->
      <!-- <p class="btn"
         @click="exportClick"><i class="icon iconfont icon-daochu btn"></i><span>导出</span></p> -->
    </div>
    <el-card style="margin-top:20px">
      <tablePage :tableData="tableData"
                 :isHeadColor="false"
                 :currentPage="currentPage"
                 :height="tableHeight"
                 @current-change="currentChange"
                 v-loading="loading"
                 ref="tableList"
                 :total="total"
                 id="table">
        <el-table-column label="基础信息"
                         min-width="300">
          <template slot-scope="scope">
            <div class="base-wrap">
              <el-avatar :size="64"
                         :src="scope.row.avatar">{{ scope.row.name }}</el-avatar>
              <div class="info-wrap">
                <p>{{ scope.row.name }}</p>
                <p>电话：{{ scope.row.phone }}</p>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="其他信息"
                         min-width="300">
          <template slot-scope="scope">
            <div>
              <div>
                <p>邀请人：{{ scope.row.inviter_name }}</p>
                <p>分成比：{{ scope.row.separate_ratio }}%</p>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="状态"
                         min-width="300">
          <template slot-scope="scope">
            <span class="blue"
                  v-show="scope.row.status === 0">待审核</span>
            <span v-show="scope.row.status === 1">审核通过</span>
            <span v-show="scope.row.status === 2">拒绝</span>
          </template>
        </el-table-column>
        <el-table-column label="注册时间"
                         prop="created"
                         min-width="300">
        </el-table-column>
        <el-table-column label="审核">
          <template slot-scope="scope">
            <span class="audit-btn"
                  @click="auditClick(scope.row)">审核</span>
          </template>
        </el-table-column>
      </tablePage>
    </el-card>
    <checkModal @submitCheck="submitCheck"
                @closeModal="closeModal"
                :checkDialogVisible="checkDialogVisible"></checkModal>
  </div>
</template>

<script>
import tablePage from 'components/tablePage'
import checkModal from './components/checkModal'
import api from '@/api/index'
import tableHeight from 'mixins/tableHeight'
export default {
  components: {
    tablePage,
    checkModal
  },
  mixins: [tableHeight],
  data () {
    return {
      checkDialogVisible: false,
      tableData: [],
      currentPage: 1,
      loading: true,
      total: 10,
      checkId: 0
    }
  },
  created () {
    this._initState()
  },
  methods: {
    closeModal () {
      this.checkDialogVisible = false
    },
    async submitCheck (params) {
      const { status_code, message } = await api.checkUpdateApi(Object.assign({ ...params }, { id: this.checkId }))
      if (status_code === 200) {
        this.$message({
          type: 'success',
          message: '审核成功'
        })
        this._initState()
        this.checkDialogVisible = false
      } else {
        if (typeof message === 'object') {
          Object.keys(message).forEach((item) => {
            this.$message.error({ message: message[item] });
          });
        } else {
          this.$message.error({ message });
        }
      }
    },
    async _initState () {
      const { status_code, message, meta: { pagination: { total } } } = await api.auditListApi()
      if (status_code === 200) {
        this.tableData = message
        this.total = total
        this.loading = false
      }
    },
    exportClick () { },
    currentChange (page) {
      this.currentPage = page
    },
    auditClick ({ id }) {
      this.checkId = id
      this.checkDialogVisible = true
    }
  }
}
</script>

<style lang="less" scoped>
.title-wrap {
  display: flex;
  justify-content: space-between;
}
h6 {
  font-size: 20px;
  color: #333333;
  font-weight: 500;
}
.btn {
  line-height: 20px;
  font-size: 16px;
  color: #3278eb;
  cursor: pointer;
  span {
    display: inline-block;
    margin-left: 4px;
  }
}
.base-wrap {
  display: flex;
}
.info-wrap {
  margin-top: 10px;
  margin-left: 10px;
}
.blue {
  color: #3278eb;
  display: inline-block;
  font-size: 14px;
  width: 54px;
  height: 22px;
  line-height: 22px;
  text-align: center;
  background: rgba(80, 157, 237, 0.1);
}
.audit-btn {
  font-size: 14px;
  color: #3278eb;
  cursor: pointer;
}
</style>